<template>
  <div class="footer bg-blue">
    <div class="article-container">
      <div class="article-1">
        <p>ELSA Mokotów</p>
        <ul>
          <li>Nr telefonu: 603 523 289</li>
          <li>Email: biuro@craftyenglish.pl</li>
          <li>Siedziba firmy: ul. Melomanów 8, 00-712 Warszawa</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts"></script>

<style scoped>
.footer {
  width: 100vw;
  height: 30vh;
  position: relative;
  margin-top: 12vw;
}
.article-container {
  position: absolute;
  top: 8vw;
  left: 0;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  column-gap: 1vw;
  margin: 0 7vw 0 7vw;
}
/* .article-1 {
  grid-column: 1 / 5;
  padding-right: 2vw;
}
.article-2 {
  grid-column: 5 / 9;
  padding-right: 2vw;
} */
.article-1 {
  grid-column: 1 / 13;
  padding-right: 2vw;
}
p {
  font-size: 3vw;
  font-weight: 700;
  line-height: 0.2;
}
ul {
  margin: 0 0 0 0;
  padding: 0 0 0 0;
  list-style-image: url("@/assets/star.svg");
}
li {
  font-size: 3vw;
  font-weight: 300;
  line-height: 1.5;
  margin: 0 0 1vw 0;
}
</style>
