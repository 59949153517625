<template>
  <div class="main-section">
    <div class="top">
      <p class="elsa red">Elsa Angielski – wyłączny dystrybutor</p>
    </div>
    <div class="logo-section">
      <img class="logo" src="@/assets/logo.svg" alt="logo Crafty English" />
      <p class="add-head red">
        Kolorowa przygoda z angielskim<br />
        <span style="font-size: 1.3vw">Zajęcia dla dzieci w wieku 4-10lat</span>
      </p>
      <p class="add red">
        Odkryj unikalny program nauki języka angielskiego, który łączy <br />
        kreatywne projekty plastyczne z przyswajaniem nowych słówek! <br />
      </p>
      <a class="btn" href="https://www.strefazajec.pl/course/view/id/66046"
        >Zapisz się!</a
      >
    </div>
    <div class="media-section">
      <img class="instagram-logo" src="@/assets/instagram_logo.svg" alt="" />
      <img class="facebook-logo" src="@/assets/facebook_logo.svg" alt="" />
      <img class="linkedin-logo" src="@/assets/linkedin_logo.svg" alt="" />
    </div>
  </div>
</template>

<script setup lang="ts"></script>

<style scoped>
.main-section {
  grid-gap: 16px;
  margin: 40px 7vw 0 7vw;
  width: 100vw;
}
.logo-section {
  margin-top: 8vw;
}
.logo {
  width: 35.4vw;
  margin-left: -0.3vw;
}
.media-section {
  width: 6.6vw;
  height: 6.6vw;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  row-gap: 1vw;
  column-gap: 1vw;
  margin: 1.5vw 0 0 0;
}
.media-section img {
  width: 2.8vw;
}
.media-section .instagram-logo {
  grid-column: 1;
  grid-row: 1;
}
.media-section .facebook-logo {
  grid-column: 1;
  grid-row: 2;
}
.media-section .linkedin-logo {
  grid-column: 2;
  grid-row: 2;
}
.elsa {
  font-size: 1vw;
  text-align: left;
  margin: 0;
  line-height: 1.2;
  font-weight: 500;
}
.add-head {
  font-size: 2vw;
  font-weight: 500;
  margin: 1.1vw 0 0.8vw 0;
  line-height: 1.2;
}
.add {
  font-size: 1.2vw;
  font-weight: 300;
  margin: 0;
  line-height: 1.5;
}
.btn {
  width: 10vw;
  height: 3vw;
  margin: 1.5vw 25vw 0 25vw;
  background-color: #346eb6;
  color: white;
  font-size: 1.2vw;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
}
</style>
