<template>
  <star
    v-for="(star, index) in stars"
    :key="index"
    :class="[`star-${star.color}`, `star-${index + 1}, star-desktop-container`]"
    :style="`width:${star.size}vw; height: ${star.size}vw; top: ${star.y}vw; left: ${star.x}vw; transform: ${star.transform}`"
  />
</template>

<script setup lang="ts">
import { ref } from "vue";
const stars = ref([
  {
    x: 49.8,
    y: -4.9,
    transform: "rotate(-26deg);",
    size: 10.8,
    color: "y",
  },
  {
    x: 70.4,
    y: -2.6,
    transform: "rotate(20deg);",
    size: 6.8,
    color: "p",
  },
  {
    x: 82.4,
    y: 3,
    transform: "rotate(-33deg);",
    size: 6.4,
    color: "y",
  },
  {
    x: 57.9,
    y: 14.5,
    transform: "rotate(-32deg);",
    size: 6.5,
    color: "p",
  },
  {
    x: 65.4,
    y: 6,
    transform: "rotate(-8deg);",
    size: 6.5,
    color: "p",
  },
  {
    x: 78.2,
    y: 12.4,
    transform: "rotate(45deg);",
    size: 4.5,
    color: "p",
  },
  // 6
  {
    x: 65.5,
    y: 26.5,
    transform: "rotate(-55deg);",
    size: 2.9,
    color: "p",
  },
  {
    x: 68.8,
    y: 18.2,
    transform: "rotate(19deg);",
    size: 12,
    color: "y",
  },
  {
    x: 85,
    y: 17.4,
    transform: "rotate(-25deg);",
    size: 4.5,
    color: "p",
  },
  // 9
  {
    x: 63.5,
    y: 36.5,
    transform: "rotate(-30deg);",
    size: 7,
    color: "p",
  },
  {
    x: 74.8,
    y: 34.3,
    transform: "rotate(19deg);",
    size: 6,
    color: "p",
  },
  {
    x: 81.1,
    y: 27.7,
    transform: "rotate(-50deg);",
    size: 7.5,
    color: "p",
  },
  // 12
  {
    x: 51.8,
    y: 41.5,
    transform: "rotate(-30deg);",
    size: 2.5,
    color: "p",
  },
  {
    x: 57.8,
    y: 44.3,
    transform: "rotate(19deg);",
    size: 3,
    color: "p",
  },
  // 12
  {
    x: 51.8,
    y: 41.5,
    transform: "rotate(-30deg);",
    size: 2.5,
    color: "p",
  },
  {
    x: 57.8,
    y: 44.3,
    transform: "rotate(19deg);",
    size: 3,
    color: "p",
  },
  {
    x: 45.3,
    y: 47.7,
    transform: "rotate(-33deg);",
    size: 6.7,
    color: "y",
  },
  // 15
  {
    x: 16.8,
    y: 48.5,
    transform: "rotate(-30deg);",
    size: 7,
    color: "y",
  },
  {
    x: 32.8,
    y: 46.3,
    transform: "rotate(45deg);",
    size: 5,
    color: "p",
  },
  {
    x: 39.3,
    y: 51.1,
    transform: "rotate(-3deg);",
    size: 3,
    color: "p",
  },
  //18
  {
    x: 24.3,
    y: 53.1,
    transform: "rotate(30deg);",
    size: 8,
    color: "p",
  },
  {
    x: 13.8,
    y: 60.3,
    transform: "rotate(45deg);",
    size: 5,
    color: "p",
  },
  {
    x: 21,
    y: 65,
    transform: "rotate(340deg);",
    size: 7,
    color: "p",
  },
  // 21
  {
    x: 9.6,
    y: 71.3,
    transform: "rotate(0deg);",
    size: 15.3,
    color: "y-perfect",
  },
  // {
  //   x: 22.3,
  //   y: 86.4,
  //   transform: "rotate(20deg);",
  //   size: 2.4,
  //   color: "p",
  // },
  // {
  //   x: 24.9,
  //   y: 84.0,
  //   transform: "rotate(333deg);",
  //   size: 4,
  //   color: "p",
  // },
  // {
  //   x: 21,
  //   y: 90,
  //   transform: "rotate(30deg);",
  //   size: 7,
  //   color: "p",
  // },
  // {
  //   x: 29,
  //   y: 93.4,
  //   transform: "rotate(120deg);",
  //   size: 9,
  //   color: "y",
  // },
  // 23
  // {
  //   x: 24.9,
  //   y: 84.0,
  //   transform: "rotate(333deg);",
  //   size: 4,
  //   color: "p",
  // },
  // {
  //   x: 21,
  //   y: 90,
  //   transform: "rotate(30deg);",
  //   size: 7,
  //   color: "p",
  // },
  // {
  //   x: 47.9,
  //   y: 103,
  //   transform: "rotate(30deg);",
  //   size: 2.5,
  //   color: "p",
  // },
  // {
  //   x: 46.6,
  //   y: 110.0,
  //   transform: "rotate(333deg);",
  //   size: 6.7,
  //   color: "p",
  // },
  // red section end up here
  // {
  //   x: 77.9,
  //   y: 200,
  //   transform: "rotate(30deg);",
  //   size: 2.5,
  //   color: "p",
  // },
  // {
  //   x: 63.6,
  //   y: 210.0,
  //   transform: "rotate(333deg);",
  //   size: 6.7,
  //   color: "p",
  // },
  // {
  //   x: 77.9,
  //   y: 210,
  //   transform: "rotate(30deg);",
  //   size: 5,
  //   color: "y",
  // },
  // {
  //   x: 60.6,
  //   y: 220.0,
  //   transform: "rotate(333deg);",
  //   size: 4,
  //   color: "p",
  // },
  // {
  //   x: 50.6,
  //   y: 224.0,
  //   transform: "rotate(333deg);",
  //   size: 3.4,
  //   color: "y",
  // },
]);
</script>

<style scoped>
.star-p {
  background-image: url(@/assets/star_pink.svg);
  background-repeat: no-repeat;
  background-size: 100% auto;
  position: absolute;
}

.star-y {
  background-image: url(@/assets/star_yellow.svg);
  background-repeat: no-repeat;
  background-size: 100% auto;
  position: absolute;
}

.star-y-perfect {
  background-image: url(@/assets/star_y_perfect_heand.svg);
  background-repeat: no-repeat;
  background-size: 100% auto;
  position: absolute;
}
</style>
